<script setup lang="ts">
  import type { Ref } from 'vue'
  import { useNuxtApp } from '#app'
  import usePagination from '~/composables/usePagination'

  const props = withDefaults(defineProps<{
    totalItems: number,
    perPage?: number,
    totalVisible?: number,
    loading?: boolean,
    scrollTo?: Ref,
  }>(), {
    perPage: 20,
    totalVisible: 8,
  })

  const emit = defineEmits(['page-change'])

  const app = useNuxtApp()

  const { totalItems, perPage } = toRefs(props)

  const route = useRoute()

  const {
    page,
    currentPage,
    pageRange,
    prev,
    next,
    totalPages
  } = usePagination(perPage, totalItems)

  const range = (from: number, to: number) => {
    const range = []
    from = from > 0 ? from : 1
    for (let i = from; i <= to; i++) {
      range.push(i)
    }
    return range
  }

  const items = computed(() => {
    if (props.totalVisible === 0) {
      return []
    }

    const maxLength = Math.min(
      Math.max(0, props.totalVisible) || totalPages.value,
      totalPages.value
    )

    if (totalPages.value <= maxLength) {
      return range(1, totalPages.value)
    }

    const even = maxLength % 2 === 0 ? 1 : 0
    const left = Math.floor(maxLength / 2)
    const right = totalPages.value - left + 1 + even

    if (currentPage.value > left && currentPage.value < right) {
      const firstItem = 1
      const lastItem = totalPages.value
      const start = currentPage.value - left + 2
      const end = currentPage.value + left - 2 - even
      const secondItem = start - 1 === firstItem + 1 ? 2 : '…'
      const beforeLastItem = end + 1 === lastItem - 1 ? end + 1 : '…'

      return [1, secondItem, ...range(start, end), beforeLastItem, totalPages.value]
    } else if (currentPage.value === left) {
      const end = currentPage.value + left - 1 - even
      return [...range(1, end), '…', totalPages.value]
    } else if (currentPage.value === right) {
      const start = currentPage.value - left + 1
      return [1, '…', ...range(start, totalPages.value)]
    } else {
      return [
        ...range(1, left),
        '…',
        ...range(right, totalPages.value),
      ]
    }
  })

  const onPageChange = () => {
    emit('page-change')
  }
</script>

<template>
  <nav class="app-pagination" v-if="totalPages > 1">
    <div class="app-pagination__batch">
      <app-button
        white
        :disabled="page === `${pageRange[0]}-${next}`"
        :loading="loading"
        :to="{ query: { ...route.query, page: `${pageRange[0]}-${next}` }}">
        <template v-if="page === `${pageRange[0]}-${next}`">
          Více už toho není
        </template>
        <template v-else>
          Načíst dalších {{ perPage }}
        </template>
      </app-button>
    </div>

    <app-card class="app-pagination__pagination">
      <div class="app-pagination__paging">
        <nuxt-link
          class="app-pagination__item app-pagination__item--prev"
          @click="onPageChange"
          :to="{ query: { ...route.query, page: prev }}">
          <mdicon
            name="chevron-left"
            class="u-mr--300"/>
          <span>Předchozí</span>
        </nuxt-link>
        <div class="app-pagination__pages">
          <template v-for="item in items">
            <nuxt-link
              v-if="item !== '…'"
              @click="onPageChange"
              :class="['app-pagination__item', { 'app-pagination__item--active': item === currentPage }]"
              :to="{ query: { ...route.query, page: item }}">
              {{ item }}
            </nuxt-link>
            <span v-else>{{ item }}</span>
          </template>
        </div>
        <nuxt-link
          class="app-pagination__item app-pagination__item--next"
          @click="onPageChange"
          :to="{ query: { ...route.query, page: next }}">
          <span>Další</span>
          <mdicon
            name="chevron-right"
            class="u-ml--300"/>
        </nuxt-link>
      </div>
    </app-card>
  </nav>
</template>

<style scoped>
  .app-pagination {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "button"
                         "pagination";
    gap: var(--gap-400);

    @media (--md) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: "button pagination pagination";
    }
  }

  .app-pagination__paging {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "prev next"
                         "pages pages";
    grid-gap: var(--gap-400);
    @media (--sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .app-pagination__pagination {
    grid-area: pagination;
  }

  .app-pagination__item--prev {
    grid-area: prev;
  }

  .app-pagination__item--next {
    grid-area: next;
    display: flex;
    justify-content: flex-end;
  }

  .app-pagination__pages {
    grid-area: pages;
    display: flex;
    justify-content: center;
  }

  .app-pagination__item {
    padding-left: var(--gap-300);
    padding-right: var(--gap-300);
    @media (--sm) {
      padding-left: var(--gap-150);
      padding-right: var(--gap-150);
    }
  }

  .app-pagination__item--active {
    color: var(--c-text);
    text-decoration: none;
  }

  .app-pagination__batch {
    grid-area: button;
    &:deep(.app-button) {
      height: 100%;
      width: 100%;
    }
  }
</style>
